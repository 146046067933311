@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    

    
    

}



body{
    font-family: 'Montserrat', sans-serif;
    font-family: 'Open Sans', sans-serif;
    background-color: #EEF7FB;


}

.container{
    max-width: 1170px;
    padding-left: 20px;
    padding-right: 20px;
    margin: auto;
    overflow-x: hidden;

}
.bgc{
    background-color: #EEF7FB;
    min-width: auto;

}

section{
    padding: 50px 0;
}

@media only screen and (max-width: 768px) {
    .menu{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: #00000084;
        z-index: 100;
        display: none;
    }
    .menu ul{
        width: 15rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 200;
        background: #EEF7FB;
        flex-direction: column;
        line-height: 30px;
        justify-content: center;
        align-items: center;

    }
    .show_menu{
        display: block;

    }
    
}
.navi.active{
    text-decoration: overline 2px;
    color: #FB8500;
  }
  .load{
    animation: fade-in-out 1s linear infinite alternate;
}
@keyframes fade-in-out{
    to{
        opacity: 0;
    }
}